<template>
	<div class="page">
		<div class="page-header hidden-sm-and-down">
			<div style="float: right">
					<el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
					<el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
			</div>
			<el-page-header @back="$router.go(-1)" content="使用说明" />
		</div>
		<div class="page-main">
			<el-row :gutter="20">
				<el-col :span="6"><el-card class="box-card">
						<el-button icon="el-icon-plus" circle></el-button> </el-card></el-col>
			</el-row>
			<el-row :gutter="20" v-for="i in 2" :key="i">
				<el-col :span="6" v-for="(o, index) in 4" :key="o" :offset="index > 0 ? 0 : 0">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>模块设计说明</span>
							<el-button style="float: right; padding: 3px 0" type="text">查看</el-button>
						</div>
						<div>
							<div class="text item">模块设计原理、测试方法介绍</div>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
export default {
	name: 'main-doc',
	path: '/main/doc',
	data() {
		return {
			title: '文档支持',
			activeName: 'first',
			isDemo: {
				type: Boolean,
				default: true,
			},
		}
	},
	methods: {
		getData() { },
	},
}
</script>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	/*justify-content: flex-start;*/
	/*align-items: flex-start;*/
	height: 100%;

	.page-header {
		padding-bottom: 10px;
	}

	.page-search {
		background-color: #f7f7f8;
		border: 1px solid #ebeef5;
		border-bottom: 0px;
		text-align: center;

		.el-form-item {
			margin: 0 5px;
		}
	}

	.page-box {
		margin-bottom: 10px;
		// background-color: #f7f7f8;
		// border: 1px solid #ebeef5;
		text-align: center;
		// padding: 0 20px;
	}

	.page-main {
		flex: 1;
		min-height: 100px;
	}

	.page-footer {
		text-align: center;
		padding-top: 20px;
	}
}

.el-row {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

.box-card {
	height: 150px;
	// text-align: center;
}

.text {
	font-size: 14px;
}

.item {
	margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: '';
}

.clearfix:after {
	clear: both;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: '';
}

.clearfix:after {
	clear: both;
}
</style>
